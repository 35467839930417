import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private $urlParts = new BehaviorSubject<any>(null);
  private url;

  constructor(private router: Router) { }

  get urlParts() {
    return this.$urlParts.asObservable().pipe(skipWhile(url => !url));
  }

  setUrl() {
    this.url = this.router.url;
    const parts = this.url.split('/');
    return this.$urlParts.next(parts);
  }
}
